import React, { createContext, useContext, useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

//Define contexto según ancho
const Contexto = createContext({});
export function useContexto() {return useContext(Contexto)};
export default function ProveedorContexto({ children }) {
  const [ ancho, setAncho ] = useState(window.innerWidth);
  useEffect(function() {
    window.addEventListener('resize', function() {return setAncho(window.innerWidth)});
    return function() {
      window.removeEventListener('resize', function() {return setAncho(window.innerWidth)});
    }
  });
  function formato() {
    const medidas = { sm:600, md:960, lg:1280 };
    if (ancho < Object.values(medidas)[Object.keys(medidas).length-1]) {
      for (let i = 0; i < Object.keys(medidas).length; i++) {
        if (ancho < Object.values(medidas)[i]) {
          return Object.keys(medidas)[i]
        }
      } 
    } else {
      return Object.keys(medidas)[Object.keys(medidas).length-1]
    }
  }
  return (
    <Contexto.Provider value={{ ancho, formato }} >
      {children}
    </Contexto.Provider>
  )
}

//Define Tema
export const Tema = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    allVariants: {color:'#222'},
    button: { textTransform:'' },
    /* h1: {fontFamily:'Open Sans, sans-serif'} */
  },
  palette: {
    azul: {
      main: "#3d6098", // azul
      light: "#5586d4",
      dark: '#2a4673'
    },
    rojo: {
      main: "#f04b4c", // rojo
      light: "#ff6e6f",
      dark: '#b53839'
    },
  }
})

//Componentes repetitivos
export const Parrafo = styled(Typography) `
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  margin: 0.5em 0;
`