import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Box } from '@material-ui/core';

const BaseContacto = styled(Grid)`
  background-color: #222;
  width: 100%;
  padding: 4vh 0 2vh;
`

export default function Contacto() {

  const equipo = [
/*     {imagen: '',
    nombre: 'Rodrigo Durán',
    profesion: 'Ingeniero Constructor',
    telefono: '+56 9 5759 9428',
    correo: 'rodrigo@modur.cl'}, */
    {imagen: '',
    nombre: 'Francisco Moreno',
    profesion: 'Arquitecto',
    telefono: '+56 9 5118 2623',
    correo: 'francisco@modur.cl'}
  ]

  return (
    <BaseContacto id='Contacto' container justifyContent='center'>
      <Grid container justifyContent='center' item xs={10}>
        {Object.values(equipo).map(function(e, i) {
          return (
            <Grid key={i} container justifyContent='center' item xs={12} sm={8} md={6}>
              <Box bgcolor='#111' borderRadius='16px' overflow='hidden' width={1} maxWidth='80%' align='center' my={4} py={4} p={2}>
              <Typography style={{textShadow:'0 0 16px #888'}} variant='h4' component='div'>
                <Box color='white' fontWeight={600} mt={2}>{e.nombre}</Box>
              </Typography>
              <Typography variant='h6' component='div'>
                <Box color='#888' fontWeight={200} mb={2}>{e.profesion}</Box>
              </Typography>
              <Typography variant='body2' component='div'>
                <Box color='white'>{e.telefono}</Box>
              </Typography>
              <Typography variant='body2' component='div'>
                <Box color='white'>{e.correo}</Box>
              </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <Grid container justifyContent='center'>
        <Typography component='div' variant='body2'>
          <Box color='#666' fontWeight={200} mt={2}>
            © 2021 modur - Chile
          </Box>
        </Typography>
      </Grid>
    </BaseContacto> 
  )
}