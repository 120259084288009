import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { StylesProvider } from "@material-ui/core/styles";
import Contexto, { Tema } from './components/contexto';
import Inicio from './components/inicio';
import Contacto from './components/contacto';
import { ThemeProvider } from '@material-ui/core/styles';

function Modur() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={Tema}>
        <Contexto>
          <Inicio />
          <Contacto />
        </Contexto>
      </ThemeProvider>
    </StylesProvider>
  )
}

ReactDOM.render(<Modur />, document.getElementById('root'));