import React, { useEffect, useRef } from 'react';
import { useContexto, Parrafo } from './contexto';
import styled, { css } from 'styled-components';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import logo from './img/logo.png';
import { DownArrow } from '@styled-icons/boxicons-solid';

const BaseInicio = styled(Grid)`
  background-color: gold;
  min-height: 100vh;
  padding: 5vh 0 2vh;
`

const TituloModur = styled(Grid)`
  background-color: rgb(0, 0, 0, .2);
  width: auto;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 16px;
  overflow: hidden;
`
const LogoModur = styled.img`
  max-height: 25vh;
  max-width: 40vw;
  margin: 30px;
  filter: contrast(2);
`
const ModularesHabitables = styled(Typography)`
  color: white;
  font-weight: 300;
  ${props => props.formato !== 'lg' && css`
  border-top: 1px solid white;
  padding: 10px 0;
  `};
  ${props => props.formato === 'lg' && css`
  border-left: 2px solid white;
  padding: 20px;
  font-weight: 200;
  `};
`

const Descripcion = styled(Grid)`
  min-height: 25vh;
`
const BotonContacto = styled(Button)`
  background-color: #3d6098;
  color: white;
  font-weight: 700;
  border-radius: 40px;
  overflow: hidden;
  margin: 4vh 0;
  padding-left: 16px;
  padding-right: 16px;
  :hover {
    background-color: #476ead;
  }
`

export default function Inicio() {
  const { ancho, formato } = useContexto();
  const sombraTitulo = useRef();
  let sombraT = '';
  const sombraBoton = useRef();
  let sombraB = '';

  for (var i = 0; i < 200; i++) {
    sombraT += (sombraT? ',' : '') + (i * 1) + 'px ' + (i * 1) + 'px 0 #a28c30';
  }
  for (var j = 0; j < 40; j++) {
    sombraB += (sombraB? ',' : '') + (j * 1) + 'px ' + (j * 1) + 'px 0 #2a4673';
  }

  useEffect(function() {
    sombraTitulo.current.style.textShadow = sombraT;
    sombraBoton.current.style.textShadow = sombraB;
  },[ancho, sombraT, sombraB])

  return (
    <BaseInicio id='Inicio' container direction='column' justifyContent='space-evenly' alignItems='center'>
        <TituloModur container direction={formato() === 'lg' ? 'row' : 'column'} alignItems='center'>
          <LogoModur src={logo} alt='modur' formato={formato()}/>
          <ModularesHabitables ref={sombraTitulo} formato={formato()} align='center'
          variant={formato() === 'sm' ? 'h3' : (formato() === 'md' ? 'h2' : 'h1')}>
            Modulares <br/> Habitables
          </ModularesHabitables>
        </TituloModur>
        <Descripcion container direction='column' alignItems='center' item xs={10} md={8}>
          <Parrafo variant={formato() === 'sm' ? 'body2' : 'body1'} align='center'>
          En <b>modur</b> desarrollamos construcciones en formato modular, mediante diseños 
          compactos y funcionales, incorporando variables medioambientales desde su génesis.
          </Parrafo>
          <BotonContacto ref={sombraBoton} href={'#Contacto'}>¿ HABLAMOS ?</BotonContacto>
        </Descripcion>
        <Grid item xs={10}>
          <Typography component='div' variant='body1' align='center'>
            <Box fontWeight={600} lineHeight={1} py={1}>
              Actualmente nuestra web se encuentra en construcción.
            </Box>
          </Typography>
        </Grid>
        <Grid container justifyContent='center' alignItems='center'>
          <DownArrow size='14'/>&nbsp;<Parrafo variant='caption'> Continúa para contactarnos</Parrafo>
        </Grid>
    </BaseInicio>
  )
}